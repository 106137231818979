import styled from "styled-components";

export const Styles = styled.div`

    .btn-large {
        padding: 0.5rem 0.6rem !important;
    }

    .mint_modal {
        background-color: black
    }

`;