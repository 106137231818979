// export const EthereumContractAddress = "0x8B022e83B7aD379DDA2e713F9939d1Ced6747e29"
// export const BinanceContractAddress = "0xb82000da482454a2995fB3ba85F8ffe72d787A72"
// export const GOLFContractAddress = "0x119920CaD17C57f4dc9042DeE7d6E4E51E81D6Ba"



export const EthereumContractAddress = "0x8B022e83B7aD379DDA2e713F9939d1Ced6747e29"
export const BinanceContractAddress = "0x14371FF088C18694428DF0EdE0420Fd056a7da96"
export const GOLFContractAddress = "0x69685772d4ac0ffC2578F31F8A5c0009E900BAf4"
// export const GOLFContractAddress = "0x6b046E05656A73459c6c7A39438b18A08AB932A4"

// export const EthereumContractAddress1 = "0x8B022e83B7aD379DDA2e713F9939d1Ced6747e29"
