import styled from "styled-components";

export const Styles = styled.div`

    min-height: 500px;
    margin-top: 10px !important;
    background-color: #202332;

    .title-text h2 span {
        color: #42a369;
    }

`;