export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SHOW_CONNECT_MODAL = "SHOW_CONNECT_MODAL";
export const HIDE_CONNECT_MODAL = "HIDE_CONNECT_MODAL";

export const SET_TOGGLE_STICKY = "SET_TOGGLE_STICKY";
export const REMOVE_TOGGLE_STICKY = "REMOVE_TOGGLE_STICKY";

export const SET_WALLET_ADDRESS = "SET_WALLET_ADDRESS";

export const SET_CHAIN_ID = "SET_CHAIN_ID";

export const SET_CONNECTED = "SET_CONNECTED";

export const SET_ACCOUNT_BALANCE = "SET_ACCOUNT_BALANCE";

export const SET_TOKEN_PRICE = "SET_TOKEN_PRICE";

export const SET_TOTAL_SUPPLY = "SET_TOTAL_SUPPLY";

export const SET_PENDING = "SET_PENDING";

export const GET_GOLFPUNKS = "GET_GOLFPUNKS";

export const GET_HISTORY = "GET_HISTORY";

export const GET_USER_DATA = "GET_USER_DATA";

export const GET_LISTED_DATA = "GET_LISTED_DATA";

export const GET_MADE_OFFERS = "GET_MADE_OFFERS";

export const GET_RECEIVED_OFFERS = "GET_RECEIVED_OFFERS";

export const GET_ACTIVITY = "GET_ACTIVITY"